// Vendor
import $ from 'jquery';
import _ from 'underscore';
import Helpers from './helpers/util';

// Modules
import Preload from './modules/preload';
import BragFacts from './modules/brags';
import Animate from './modules/animate';

// Resize
let resizeLayout;
if (window.Modernizr.touch) {
	resizeLayout = function() {
		Helpers.pubsub.publish('onResize');
	};
} else {
	resizeLayout = _.debounce(function() {
		Helpers.pubsub.publish('onResize');
	}, 250);
}

$(window).on({
	resize: function() {
		if (!window.Modernizr.touch) {
			resizeLayout();
		}
	},
	orientationchange: resizeLayout,
	unload: function() {
		$(this).scrollTop(0);
	}
});

new Preload();
new BragFacts();
new Animate();